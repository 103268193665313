<template>
  <div data-cy="subscribe">
    <strong class="u-display--block u-margin-bottom--1"
      >I need more travel! Please brighten my inbox with hot deals, new trips
      and great tales.</strong
    >
    <Button
      data-cy="subscribe_button"
      :onClick="() => invokeModal(true)"
      class="button--primary button--block"
    >
      <Icon class="u-margin-right--1" name="email-outline" />
      Subscribe
    </Button>
    <SubscriptionForm
      v-if="formstackIds"
      :show-modal="showSubscriptionModal"
      :formstack-ids="formstackIds"
      @close="() => invokeModal(false)"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import "atlas/src/components/Icon/icons/email-outline.js";
import SubscriptionForm from "./SubscriptionForm.vue";
import { subscriptionForm } from "~/config/formstackForms";
import { Environment } from "~/.aws/lib/types";
import { SubscriptionFormFields } from "~/components/Subscription/Props";

export default Vue.extend({
  name: "Subscription",
  components: {
    SubscriptionForm,
    Button,
    Icon,
  },
  data(): {
    showSubscriptionModal: boolean;
    formstackIds: SubscriptionFormFields | undefined;
  } {
    return {
      showSubscriptionModal: false,
      formstackIds: undefined,
    };
  },
  mounted() {
    this.getFormstackIds();
  },
  methods: {
    invokeModal(status: boolean): void {
      this.showSubscriptionModal = status;
    },
    getFormstackIds(): void {
      this.formstackIds = subscriptionForm?.uat;
      if (
        this.$config?.public?.environment === Environment.PRD ||
        this.$config?.public?.environment === Environment.STAGING
      ) {
        this.formstackIds = subscriptionForm?.prd;
      }
    },
  },
});
</script>
