<template>
  <ul
    v-if="menu.href && menu.name"
    class="footer-menu u-margin-top--0 u-margin-bottom--1 u-padding-left--0"
  >
    <li class="u-list-style-type--none">
      <Link
        :href="menu.href"
        class="u-color--text u-text-decoration--none u-font-weight--bold"
      >
        {{ menu.name }}
      </Link>
      <ul
        v-if="menu.children"
        class="u-margin-top--0 u-margin-bottom--1 u-padding-left--0"
      >
        <li
          v-for="child in menuChildren"
          :key="child.name"
          class="u-padding-top--1 u-list-style-type--none"
        >
          <small>
            <Link
              :href="child.href"
              :rel="child.rel || null"
              class="
                u-color--text
                u-text-decoration--none
                hover:u-text-decoration--underline
                focus:u-text-decoration--underline
              "
            >
              {{ child.name }}
            </Link>
          </small>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import type Menu from "~/lib/types/Menu";
import Link from "~/components/Link/Link.vue";

export default Vue.extend({
  name: "FooterMenu",
  components: {
    Link,
  },
  props: {
    menu: {
      type: Object as PropType<Menu>,
      required: true,
    },
  },
  computed: {
    menuChildren(): Menu[] {
      return this.menu.children?.filter(({ href, name }) => href && name) ?? [];
    },
  },
});
</script>
