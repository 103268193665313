<template>
  <div><slot></slot></div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "App",
  data(): { newRelicScript: string } {
    return {
      newRelicScript: "",
    };
  },
  async fetch() {
    this.newRelicScript = await $fetch("/api/nuxt/global/newrelic", {
      parseResponse: (text) => text,
    });
  },
  head() {
    return {
      script: [
        {
          hid: `newrelic_config`,
          body: true,
          innerHTML: this.newRelicScript,
          type: "text/javascript",
        },
        {
          hid: `fontface_observer`,
          type: "text/javascript",
          innerHTML: `
            (function() {
              const setFontLoadedClassOnBody = () => {
                if(!document.body.classList.contains('font-loaded')) {
                  document.body.classList.add('font-loaded');
                }
              }
              if(document.fonts && document.fonts.ready && document.fonts.check) {
                const fontLoadTimer = setInterval(() => {
                  if(document.fonts.check("16px Montserrat")) {
                    setFontLoadedClassOnBody();
                  }
                }, 15);
                document.fonts && document.fonts.ready.then(() => {
                  setFontLoadedClassOnBody();
                  clearInterval(fontLoadTimer);
                });
              } else {
                window.addEventListener('load', function() {
                  setFontLoadedClassOnBody();
                })
              }
            })()
          `,
        },
      ],
      __dangerouslyDisableSanitizersByTagID: {
        newrelic_config: ["script", "innerHTML"],
        fontface_observer: ["script", "innerHTML"],
      },
    };
  },
});
</script>
<style>
body:not(.font-loaded) {
  letter-spacing: 0.05em;
}
</style>
