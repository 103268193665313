<template>
  <nav
    ref="megaMenu"
    v-click-outside="close"
    class="mega-menu"
    data-cy="mega-menu"
    aria-label="Navigation Submenu"
    :style="menuPosition"
    @keydown.escape="close"
  >
    <div
      v-for="(level1, level1Index) in menu"
      :key="level1Index"
      class="mega-menu__level-1"
      :class="{
        'mega-menu__level-1--visible': level1Index === activeLevels[1],
      }"
      data-cy="mega-menu__level-1"
    >
      <div v-if="hasChildren(level1.children)" class="mega-menu__level-2">
        <ul ref="level2Items" class="mega-menu__level-2-items">
          <li
            v-for="(level2, level2Index) in level1.children"
            :key="uniqueRefKey(level1Index, level2Index)"
            :ref="uniqueRefKey(level1Index, 'level2Item')"
            class="mega-menu__level-2-item"
          >
            <Link
              v-if="!hasChildren(level2.children)"
              :ref="uniqueRefKey(level1Index, level2Index, 'link')"
              :href="level2.href"
              class="mega-menu__level-2-link"
              data-cy="mega-menu__level-2-link"
            >
              {{ level2.name }}
            </Link>
            <button
              v-if="hasChildren(level2.children)"
              :ref="uniqueRefKey(level1Index, level2Index, 'link')"
              class="mega-menu__level-2-link"
              :class="{
                'mega-menu__level-2-link--active':
                  level2Index === activeLevels[2],
              }"
              data-cy="mega-menu__level-2-link"
              @click="level2Clicked(level2Index)"
              @keydown.space="level2Clicked(level2Index)"
            >
              {{ level2.name }}
              <Icon
                name="chevron-right"
                class="mega-menu__level-2-link-chevron"
              />
            </button>
            <div
              v-if="hasChildren(level2.children)"
              class="mega-menu__level-3"
              :href="level2.href"
              :class="{
                'mega-menu__level-3--visible':
                  level1Index === activeLevels[1] &&
                  level2Index === activeLevels[2],
              }"
            >
              <div class="mega-menu__remove-inner-shadow"></div>
              <Link
                :href="level2.href"
                class="mega-menu__level-3-title"
                data-cy="mega-menu__level-3-link"
              >
                {{ level2.name }}
              </Link>
              <ul
                class="mega-menu__level-3-items"
                :class="level3ItemsClasses(level2.children)"
              >
                <li
                  v-for="(level3, level3Index) in level2.children"
                  :key="uniqueRefKey(level1Index, level2Index, level3Index)"
                  class="mega-menu__level-3-item"
                  @click="close"
                >
                  <Link
                    :href="level3.href"
                    class="mega-menu__level-3-link"
                    data-cy="mega-menu__level-3-link"
                  >
                    {{ level3.name }}
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import ClickOutside from "atlas/src/directives/ClickOutside/ClickOutside.js";
import Menu from "~/lib/types/Menu";
import "atlas/src/components/Icon/icons/chevron-right.js";
import Link from "~/components/Link/Link.vue";
import { HeaderBarClickPayload } from "~/lib/types/HeaderBarClickPayload";

const COLUMN_TRIGGER = 10;

export default Vue.extend({
  name: "MegaMenu",
  components: {
    Icon,
    Link,
  },
  directives: {
    ClickOutside,
  },
  props: {
    menu: {
      type: Array as PropType<Menu[]>,
      required: false,
      default() {
        return [];
      },
    },
    linkClicked: {
      type: Object as PropType<HeaderBarClickPayload>,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      recentlyOpened: false,
      clickedPayload: null as null | HeaderBarClickPayload,
      activeLevels: {
        1: null,
        2: null,
      } as Record<number, null | number>,
    };
  },
  computed: {
    menuPosition() {
      if (!this.clickedPayload) {
        return {};
      }

      if (
        !this.clickedPayload.buttonElement ||
        !this.clickedPayload.containerElement
      ) {
        return {};
      }

      if (this.activeLevels[1] === null) {
        return {};
      }

      const styles = {
        left: 0,
      };

      if (
        this.$refs.level2Items &&
        (this.$refs.level2Items as Element[])[this.activeLevels[1]]
      ) {
        const targetLeft =
          this.clickedPayload.buttonElement.getBoundingClientRect().left;
        const megaMenuLeft =
          this.clickedPayload.containerElement.getBoundingClientRect().left;

        (styles.left as number) += targetLeft - megaMenuLeft;

        const level = this.$props.menu[this.activeLevels[1]].children;

        if (
          this.activeLevels[2] !== null &&
          level?.[this.activeLevels[2]]?.children?.length > 0
        ) {
          (styles.left as number) -= (
            (this.$refs.level2Items as [])[this.activeLevels[1]] as HTMLElement
          ).getBoundingClientRect().width;
        }
      }

      (styles.left as string | number) = styles.left + "px";

      return styles;
    },
  },
  watch: {
    linkClicked(payload) {
      if (!payload) {
        return;
      }

      this.setClickedPayload(payload);

      const item = payload.data;

      if (!item.href) {
        return;
      }

      const href = item.href;

      this.resetActiveLevels();

      this.$props.menu.forEach((menuItem: Menu, index: number) => {
        if (menuItem?.href?.toLowerCase() === href.toLowerCase()) {
          if (this.activeLevels[1] !== index) {
            this.setActiveLevel(1, index);
          }
        }
      });

      this.triggerRecentlyOpened();
      this.level2Clicked(0);
      this.level2Focus(this.activeLevels[1] as number, 0);
    },
  },

  methods: {
    isOpen() {
      return this.activeLevels[1] !== null;
    },
    resetActiveLevels() {
      this.activeLevels = {
        1: null,
        2: null,
      };
    },
    resetClickedPayload() {
      this.clickedPayload = null;
    },
    triggerRecentlyOpened() {
      this.recentlyOpened = true;

      setTimeout(() => {
        this.recentlyOpened = false;
      }, 100);
    },
    setActiveLevel(level: number, index: number) {
      this.activeLevels[level] = index;
    },
    setClickedPayload(payload: HeaderBarClickPayload) {
      this.clickedPayload = payload;
    },
    close() {
      if (!this.recentlyOpened) {
        this.resetActiveLevels();
        this.resetClickedPayload();
        this.$emit("close");
      }
    },
    level2Clicked(index: number) {
      if (
        this.activeLevels[1] !== null &&
        this.$props?.menu[this.activeLevels[1]] &&
        this.$props?.menu[this.activeLevels[1]]?.children
      ) {
        this.activeLevels[2] = index;
      }
    },
    level3ItemsClasses(items?: Menu[]) {
      const classes: Array<string> = [];

      if (!items || !items.length) {
        return classes;
      }

      if (items.length > COLUMN_TRIGGER) {
        classes.push(
          "mega-menu__level-3-items--column-" +
            Math.ceil(items.length / COLUMN_TRIGGER)
        );
      }

      return classes;
    },
    level2Focus(level1Index: number, level2Index: number) {
      if (
        !this.$props?.menu[level1Index]?.children ||
        !this.$props.menu[level1Index].children[level2Index] ||
        !this.$props.menu[level1Index].children[level2Index]?.children ||
        this.$props.menu[level1Index].children[level2Index]?.children.length ===
          0
      ) {
        return;
      }

      setTimeout(() => {
        (
          (this.$refs[level1Index + "-" + level2Index + "-link"] as [])[
            level2Index
          ] as HTMLElement
        ).focus();
      });
    },
    uniqueRefKey(...args: (string | number)[]) {
      return args.join("-");
    },
    hasChildren(items?: Menu[]) {
      if (!items || items.length <= 0) {
        return false;
      }

      return true;
    },
  },
});
</script>

<style lang="scss">
@import "../../components/MegaMenu/mega-menu";
</style>
