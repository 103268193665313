<template>
  <NotificationBar
    v-if="description"
    :message="description"
    :url="getLocalizedUrlOrEmptyString"
    :action="callToAction"
    data-cy="notification-bar"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import NotificationBar from "atlas/src/components/NotificationBar/NotificationBar.vue";
import { NotificationProps } from "./Props";

export default Vue.extend({
  name: "Notification",
  components: {
    NotificationBar,
  },
  props: {
    description: {
      type: String as PropType<NotificationProps["description"]>,
      required: false,
      default: "",
    },
    url: {
      type: String as PropType<NotificationProps["url"]>,
      required: false,
      default: "",
    },
    callToAction: {
      type: String as PropType<NotificationProps["callToAction"]>,
      required: false,
      default: "",
    },
  },
  computed: {
    // An empty url should not display a link, but localization will always cause url to have a value, hence the check for empty url
    getLocalizedUrlOrEmptyString() {
      return this.url ? this.$link.create(this.url) : "";
    },
  },
});
</script>

<style lang="scss">
@import "./notification";
</style>
