<template>
  <div
    v-if="isRegionSelectorVisible"
    class="region-selector"
    data-cy="region-selector"
  >
    <SelectField
      id="available-regions"
      v-model="selectedRegionModel"
      class="field--full-width"
      name="available-regions"
      :options="regionOptions"
      :error="selectedRegionError"
      label="Change region"
      @input="onChange"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import SelectField from "atlas/src/components/SelectField/SelectField.vue";
import Locale from "~/lib/types/Locale";

export default Vue.extend({
  name: "RegionSelector",
  components: {
    SelectField,
  },
  props: {
    currentRegion: {
      type: String,
      required: true,
    },
    availableRegions: {
      type: Array as PropType<Locale[]>,
      required: true,
    },
  },
  data() {
    return {
      selectedRegionModel: this.currentRegion,
      selectedRegionError: [],
    };
  },
  computed: {
    isRegionSelectorVisible(): boolean {
      return this.availableRegions.some((locale: Locale) => {
        return (
          locale.code === this.currentRegion && locale.isRegionSelectorVisible
        );
      });
    },
    regionOptions(): Array<object> {
      const regions = this.availableRegions.map((locale: Locale) => ({
        id: locale.code,
        value: locale.name,
      }));

      return regions.sort((a, b) => (a.value > b.value ? 1 : -1));
    },
  },
  methods: {
    async goToRegion(availableRegions: Locale[], regionCode: string) {
      const locale = availableRegions.find(({ code }) => code === regionCode);

      if (!locale) return false;

      await this.$store.dispatch("user/setPreferredRegion", locale);

      window.location.href = this.switchLocalePath(locale.code);
      return true;
    },
    async onChange(newRegion: string) {
      await this.goToRegion(this.availableRegions, newRegion);
    },
  },
});
</script>
