<template>
  <div v-if="title || image" class="page-banner">
    <h1
      class="
        l-container
        sm:u-text-align--center
        u-margin-bottom--1
        u-margin-top--3
      "
    >
      {{ title }}
    </h1>
    <Banner
      v-if="image"
      :lazy-src="image.src"
      :alt="image.alt"
      :lazy-srcset="image.srcSet"
      scale-xs="96:25"
      scale-sm="96:25"
      scale-md="96:25"
      scale-lg="96:25"
      scale-xl="96:25"
    >
    </Banner>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Banner from "atlas/src/components/Banner/Banner.vue";
import { MetaInfo } from "vue-meta";
import Image from "~/lib/types/Image";

export default Vue.extend({
  name: "PageBanner",
  components: {
    Banner,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: Object as PropType<Image>,
      required: false,
      default: undefined,
    },
  },
  head(): MetaInfo {
    return {
      link: this.image
        ? [
            {
              rel: "preload",
              as: "image",
              href: this.image?.src,
              imagesrcset: this.createSrcSet(this.image?.srcSet),
              imagesizes: "100vw",
            },
          ]
        : [],
    };
  },
  methods: {
    createSrcSet(srcSet?: { src: string; size: string }[]): string | undefined {
      return srcSet?.map(({ src, size }) => `${src} ${size}`).join(", ");
    },
  },
});
</script>
