import Vue from "vue";
import Cookies from "js-cookie";
import HeaderBar from "atlas/src/components/HeaderBar/HeaderBar.vue";
import HeaderBarUtility from "atlas/src/components/HeaderBar/HeaderBarUtility.vue";
import HeaderBarMobileDropDownUtility from "atlas/src/components/HeaderBar/HeaderBarMobileDropDownUtility.vue";
import WindowSize from "atlas/src/mixins/WindowSize/WindowSize.js";
import MegaMenu from "../MegaMenu/MegaMenu.vue";
import WishlistHeaderBarCount from "../WishlistHeaderBarCount/WishlistHeaderBarCount.vue";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary.vue";
import { getBaseLink } from "~/lib/utils/link/getBaseLink";
import "atlas/src/components/Icon/icons/account.js";
import "atlas/src/components/Icon/icons/phone.js";
import Notification from "~/components/Notification/Notification.vue";
import FindTrip from "~/components/FindTrip/FindTrip.vue";
import CookieKey from "~/lib/types/CookieKey";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Feature.Header]
});
export default Vue.extend({
  name: "Header",
  components: {
    HeaderBar,
    HeaderBarUtility,
    MegaMenu,
    Notification,
    HeaderBarMobileDropDownUtility,
    FindTrip,
    ErrorBoundary,
    WishlistHeaderBarCount
  },
  mixins: [WindowSize],
  data() {
    return {
      affix: true,
      mobileUtilities: [{
        href: this.$link.create("/booking/manage-booking"),
        name: "My Booking",
        rel: "nofollow"
      }],
      linkClicked: null,
      isMegaMenuOpen: false,
      breakpoint: null,
      salesCountryPhoneNumber: "",
      notificationClass: "notification--text-hidden"
    };
  },
  head() {
    var _this$salesRegionCode;
    return {
      link: [{
        rel: "preload",
        as: "image",
        href: "/files/images/logos/intrepid-logo.svg"
      }, {
        rel: "preload",
        as: "image",
        href: "/files/images/logos/intrepid-logo-small.svg"
      }],
      script: [{
        body: true,
        json: {
          "@context": "https://schema.org",
          "@type": "TravelAgency",
          url: "https://www.intrepidtravel.com",
          name: "Intrepid Travel",
          telephone: this.$data.salesCountryPhoneNumber || this.salesRegionPhoneNumber,
          address: [{
            addressCountry: ((_this$salesRegionCode = this.salesRegionCode) === null || _this$salesRegionCode === void 0 ? void 0 : _this$salesRegionCode.toUpperCase()) || ""
          }]
        },
        type: "application/ld+json"
      }]
    };
  },
  computed: {
    showMegaMenu() {
      return this.breakpoint === "md" || this.breakpoint === "lg" || this.breakpoint === "xl";
    },
    menuItemsHeaderbar() {
      if (!this.menuItems || this.menuItems.length === 0) {
        return [];
      }
      const getRegionalizeLink = menuItem => {
        return "children" in menuItem ? menuItem.href : `${this.$link.create(menuItem.href)}`;
      };
      const menuitemsRegionalized = this.menuItems.map(menuItem => {
        const level1MenuItem = {
          ...menuItem,
          href: getRegionalizeLink(menuItem)
        };
        if ("children" in level1MenuItem) {
          const level1MenuItemChildren = level1MenuItem.children.map(menuItem => {
            const level2MenuItem = {
              ...menuItem,
              href: getRegionalizeLink(menuItem)
            };
            if ("children" in level2MenuItem) {
              const level2MenuItemChildren = level2MenuItem.children.map(menuItem => {
                return {
                  ...menuItem,
                  href: getRegionalizeLink(menuItem)
                };
              });
              level2MenuItem.children = level2MenuItemChildren;
            }
            return level2MenuItem;
          });
          level1MenuItem.children = level1MenuItemChildren;
        }
        return level1MenuItem;
      });
      return menuitemsRegionalized;
    },
    menus() {
      if (!this.menuItems || this.menuItems.length === 0) {
        return [];
      }
      if (this.menuItems[0].name.toLowerCase().startsWith("home")) {
        return this.menuItems.slice(1, this.menuItems.length);
      }
      return this.menuItems;
    },
    salesRegionPhoneNumber() {
      return this.$store.getters["salesRegion/directPhoneNumber"];
    },
    notification() {
      return this.$store.getters["header/getNotification"];
    },
    menuItems() {
      return this.$store.getters["header/getMenuitems"];
    },
    pageType() {
      return this.$store.getters["page/getPageType"];
    },
    isHomePage() {
      return getBaseLink(this.$route.path) === "" || getBaseLink(this.$route.path) === "/preview/home";
    },
    salesRegionCode() {
      return this.$store.getters["salesRegion/regionCode"];
    }
  },
  mounted() {
    this.onWindowResize(this.setBreakpoint);
  },
  async created() {
    const iso = Cookies.get(CookieKey.OriginCountry);
    if (process.client) {
      await this.dispatchGetNotificationAndDealsMenuItems();
      this.notificationClass = "";
    }
    if (process.client && iso) {
      await this.fetchSalesCountry(iso);
    }
  },
  methods: {
    async dispatchGetNotificationAndDealsMenuItems() {
      try {
        const pathForHeader = getBaseLink(this.$route.path);
        await this.$store.dispatch("header/getNotificationAndDealsMenuItems", {
          locale_iso: this.$i18n.localeProperties.iso,
          locale_code: this.$i18n.locale,
          locale_domain: useRuntimeConfig().public.baseUrl,
          path: this.$route.path,
          slug: pathForHeader || "/",
          currency_code: this.$route.params.currencyCode || this.$i18n.localeProperties.currencyCode,
          current_path: pathForHeader || "<front>",
          page_type: this.pageType
        });
      } catch (error) {
        logger.error("Exception in dispatching header", {
          functionName: "dispatchGetNotificationAndDealsMenuItems",
          error
        });
      }
    },
    async fetchSalesCountry(iso) {
      let salesCountry;
      try {
        var _salesCountry;
        salesCountry = await $fetch("/api/nuxt/sales-country", {
          params: {
            iso: iso.toLowerCase()
          }
        });
        if ((_salesCountry = salesCountry) !== null && _salesCountry !== void 0 && _salesCountry.phone_number) {
          var _salesCountry2;
          this.salesCountryPhoneNumber = (_salesCountry2 = salesCountry) === null || _salesCountry2 === void 0 ? void 0 : _salesCountry2.phone_number;
        }
      } catch (error) {
        logger.error("Fetching Sales Country failed", {
          functionName: "fetchSalesCountry",
          iso,
          salesCountry,
          error
        });
      }
    },
    menuClicked(data) {
      this.linkClicked = data;
      this.isMegaMenuOpen = true;
    },
    closeMegaMenu() {
      this.$nextTick(() => {
        this.isMegaMenuOpen = false;
      });
    },
    setBreakpoint(windowSize) {
      this.breakpoint = windowSize.breakpoint;
    }
  }
});