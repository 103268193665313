import utc from "dayjs/plugin/utc.js";
import dayjs, { extend } from "dayjs";
import { SubscriptionFormDataProps, SubscriptionFormFields } from "./Props";
import { FormstackDataProps } from "~/components/ContactUsForm/Props";

extend(utc);

export const SubscriptionFormMapper = (
  formstackIds: SubscriptionFormFields,
  formData: SubscriptionFormDataProps
): FormstackDataProps => {
  return {
    [`field_${formstackIds?.first_name}`]: formData.firstName,
    [`field_${formstackIds?.last_name}`]: formData.lastName,
    [`field_${formstackIds?.email}`]: formData.email,
    [`field_${formstackIds?.country_of_residence}`]:
      formData.countryOfResidence,
    [`field_${formstackIds?.privacy_policy}`]: "true",
    [`field_${formstackIds?.source}`]: formData.source,
    [`field_${formstackIds?.source_type}`]: formData.sourceType,
    [`field_${formstackIds?.submitted_datetime}`]: dayjs
      .utc(new Date())
      .toISOString(),
  };
};
