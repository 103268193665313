<template>
  <div v-if="accreditations" class="l-grid__cell l-grid__cell--12-col">
    <component
      :is="accreditation.link ? 'a' : 'span'"
      v-for="(accreditation, index) in accreditations"
      :key="index"
      :href="accreditation.link"
      class="u-margin-right--1 sm:u-margin-right--2"
      :target="accreditation.link ? '_blank' : null"
    >
      <Imagery
        :key="index"
        :lazy-src="accreditation.src"
        :alt="accreditation.alt"
        :aria-label="accreditation.alt"
        :width="accreditation.width"
        :height="accreditation.height"
        type="static"
        data-cy="accreditation"
        class="u-display--inline-block u-margin-bottom--1"
      />
    </component>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import Image from "~/lib/types/Image";

export default Vue.extend({
  name: "FooterAccreditations",
  components: {
    Imagery,
  },
  computed: {
    accreditations(): Image[] | undefined {
      if (this.$i18n.locale === "uk") {
        return [
          {
            src: "/files/images/accreditations/abta.svg",
            alt: "ABTA",
            height: 50,
            width: 118,
          },
          {
            src: "/files/images/accreditations/atol.svg",
            alt: "ATOL",
            height: 50,
            width: 50,
          },
          {
            src: "/files/images/accreditations/global-compact.svg",
            alt: "Global Compact",
            height: 50,
            width: 50,
          },
          {
            src: "/files/images/accreditations/abtot.svg",
            alt: "ABTOT",
            height: 50,
            width: 120,
            link: "https://www.abtot.com/",
          },
        ];
      }
      if (this.$i18n.locale === "de") {
        return [
          {
            src: "/files/images/accreditations/gecko.svg",
            alt: "Gecko",
            height: 50,
            width: 110,
          },
          {
            src: "/files/images/accreditations/global-compact.svg",
            alt: "Global Compact",
            height: 50,
            width: 50,
          },
        ];
      }
      if (this.$i18n.locale === "nl") {
        return [
          {
            src: "/files/images/accreditations/garantiefondsen-logos.svg",
            alt: "Garantiefondsen",
            height: 50,
            width: 140,
          },
        ];
      }

      return undefined;
    },
  },
});
</script>
