<template>
  <div
    v-click-outside="closeMenu"
    :class="isCompact() ? 'autocomplete autocomplete--compact' : 'autocomplete'"
    data-cy="autocomplete"
  >
    <div class="autocomplete__input-wrapper">
      <input
        :placeholder="placeholder"
        aria-label="Search for a Destination, Trip name or Tour code"
        :value="inputValue.id"
        class="autocomplete__input"
        data-cy="autocomplete__input"
        autocomplete="off"
        spellcheck="false"
        @input="handleInput"
        @keydown="handleKeyDown"
        @focus="handleFocus"
      />
      <Icon
        class="autocomplete__result-input-icon"
        data-cy="autocomplete-result-input-icon"
        :name="inputIcon"
      ></Icon>
      <button
        v-if="showClearButton"
        aria-label="Clear search"
        class="autocomplete__clear-button"
        data-cy="autocomplete-clear-button"
        @click="clearInput"
      >
        <Icon
          name="close-circle"
          class="icon--inline"
          role="presentation"
        ></Icon>
      </button>
    </div>

    <ul
      v-if="showAutocompleteResults"
      id="autocomplete-results"
      class="autocomplete__results"
      data-cy="autocomplete__results"
    >
      <li
        v-for="(item, index) in items"
        :key="item.id"
        :class="[
          'autocomplete__result',
          isFocused(index) ? 'autocomplete__result--focused' : '',
        ]"
        data-cy="autocomplete__result"
        @click="selectItem(index)"
      >
        <span v-if="item.icon" class="autocomplete__result-icon">
          <Icon
            :name="item.icon"
            class="icon--inline"
            role="presentation"
          ></Icon>
        </span>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="item.value"></span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { throttle, debounce } from "throttle-debounce";

import Icon from "atlas/src/components/Icon/Icon.vue";
import ClickOutside from "atlas/src/directives/ClickOutside/ClickOutside.js";
import "atlas/src/components/Icon/icons/magnify.js";
import "atlas/src/components/Icon/icons/close-circle.js";

type Value = {
  id: string;
  value: string;
  icon?: string;
  clearAutocomplete?: boolean;
};

type Data = {
  suggestedValue: Value;
  isMenuOpen: boolean;
  itemFocusedIndex: number;
  inputThrottled: Function;
  inputDebounced: Function;
};

const emptyValue: Value = {
  id: "",
  value: "",
};

export default Vue.extend({
  name: "Autocomplete",
  components: {
    Icon,
  },
  directives: {
    ClickOutside,
  },
  props: {
    value: {
      type: Object as PropType<Value>,
      required: true,
      default: () => {
        return emptyValue;
      },
      validator(value: Value) {
        if (!("id" in value) || typeof value.id !== "string") {
          return false;
        }

        if (!("value" in value) || typeof value.value !== "string") {
          return false;
        }

        if ("icon" in value && typeof value.icon !== "string") {
          return false;
        }

        return !(
          "clearAutocomplete" in value &&
          typeof value.clearAutocomplete !== "boolean"
        );
      },
    },
    items: {
      type: Array as PropType<Value[]>,
      required: true,
    },
    throttleCharacterLimit: {
      type: Number,
      default: 5,
    },
    debounceDuration: {
      type: Number,
      default: 500,
    },
    variant: {
      type: String,
      default: "default",
      required: false,
      validator(val) {
        return val === "default" || val === "compact";
      },
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      suggestedValue: this.value,
      isMenuOpen: false,
      itemFocusedIndex: -1,
      inputThrottled: Function,
      inputDebounced: Function,
    };
  },
  computed: {
    inputValue(): Value {
      if (
        this.showAutocompleteResults &&
        this.$data.suggestedValue &&
        "id" in this.$data.suggestedValue
      ) {
        return this.$data.suggestedValue;
      }

      return this.value;
    },
    hasItems(): boolean {
      return this.$props.items.length > 0;
    },
    showAutocompleteResults(): boolean {
      return this.$data.isMenuOpen && this.hasItems;
    },
    showClearButton(): boolean {
      return !!(this.inputValue.id && this.inputValue.id.length > 0);
    },
    inputIcon(): string {
      if (this.inputValue && this.inputValue.icon) {
        return this.inputValue.icon;
      }

      return "magnify";
    },
  },
  created() {
    this.inputThrottled = throttle(this.debounceDuration, this.emitInput);
    this.inputDebounced = debounce(this.debounceDuration, this.emitInput);
  },
  methods: {
    setSelectedValueByIndex(index: number) {
      const item = this.items[index];

      if (!item || !item.id || !item.value) return;

      this.$data.suggestedValue = item;
    },
    emitInput(): void {
      this.$emit("input", this.$data.suggestedValue);
    },
    emitItemSelect(): void {
      this.closeMenu();
      this.$emit("itemselect", this.$data.suggestedValue);
    },
    emitSelect(): void {
      this.closeMenu();
      this.$emit("select", this.$data.suggestedValue);
    },
    clearInput() {
      this.$data.itemFocusedIndex = -1;
      this.$data.suggestedValue = emptyValue;
      this.$emit("clear");
    },
    isFocused(index: number) {
      return this.$data.itemFocusedIndex === index;
    },
    resetSuggestedValue(value: Value): void {
      if (
        "clearAutocomplete" in this.value &&
        this.value.clearAutocomplete === true
      ) {
        this.$data.suggestedValue = value;
      }
    },
    closeMenu() {
      this.resetSuggestedValue(this.value);
      this.$data.isMenuOpen = false;
    },
    openMenu() {
      this.$data.isMenuOpen = true;
    },
    handleFocus() {
      if (this.$data.suggestedValue.clearAutocomplete === true) {
        this.$data.suggestedValue = emptyValue;
        this.$data.itemFocusedIndex = -1;
      }

      this.openMenu();
    },
    handleInput(e: Event) {
      this.openMenu();

      const target = e.target;

      if (!target) {
        return;
      }

      const value = (target as HTMLInputElement).value;

      this.suggestedValue = {
        id: value,
        value,
      };

      this.$data.itemFocusedIndex = -1;

      if (value.length < this.throttleCharacterLimit) {
        this.$data.inputThrottled();
      } else {
        this.$data.inputDebounced();
      }
    },
    handleKeyDown(e: KeyboardEvent) {
      const DOWN = 40;
      const UP = 38;
      const ENTER = 13;
      const ESC = 27;
      const TAB = 9;

      switch (e.keyCode) {
        case DOWN:
          this.handleDownArrow(e);
          break;
        case UP:
          this.handleUpArrow(e);
          break;
        case ENTER:
          this.handleEnter(e);
          break;
        case TAB:
          this.closeMenu();
          break;
        case ESC:
          this.closeMenu();
          break;
      }
    },
    handleUpArrow(e: Event) {
      e.preventDefault();

      if (!this.$data.isMenuOpen) {
        this.openMenu();

        return;
      }

      this.$data.itemFocusedIndex--;

      if (this.$data.itemFocusedIndex === -1) {
        this.$data.suggestedValue = this.value;
        return;
      }

      if (this.$data.itemFocusedIndex < -1) {
        this.$data.itemFocusedIndex = this.items.length - 1;
      }

      this.setSelectedValueByIndex(this.$data.itemFocusedIndex);
    },
    handleDownArrow(e: Event) {
      e.preventDefault();

      if (!this.$data.isMenuOpen) {
        this.openMenu();

        return;
      }

      this.$data.itemFocusedIndex++;

      if (this.$data.itemFocusedIndex === this.items.length) {
        this.$data.suggestedValue = this.value;
        return;
      }

      if (this.$data.itemFocusedIndex > this.items.length) {
        this.$data.itemFocusedIndex = 0;
      }

      this.setSelectedValueByIndex(this.$data.itemFocusedIndex);
    },
    handleEnter(e: Event) {
      e.preventDefault();

      const inFocusRange =
        this.$data.itemFocusedIndex > -1 &&
        this.$data.itemFocusedIndex < this.items.length;

      if (this.$data.isMenuOpen && inFocusRange) {
        this.selectItem(this.$data.itemFocusedIndex);
      } else {
        this.emitSelect();
      }
    },
    selectItem(index: number) {
      this.setSelectedValueByIndex(index);

      this.emitItemSelect();
    },
    isCompact() {
      return this.variant === "compact";
    },
  },
});
</script>

<style lang="scss">
@import "./autocomplete";
</style>
