<template>
  <form ref="wtphForm" class="wtphForm" @submit.prevent="handleSubmit">
    <TextArea
      v-model="feedback"
      :label="formLabel"
      :name="`field_${formFields?.description}`"
    />
    <input
      type="hidden"
      data-cy="wtph-field-wtph"
      :name="`field_${formFields?.wtph}`"
      :value="wtph"
    />
    <input
      data-cy="wtph-field-url"
      type="hidden"
      :name="`field_${formFields?.pageUrl}`"
      :value="pageUrl"
    />
    <input
      data-cy="wtph-field-title"
      type="hidden"
      :name="`field_${formFields?.pageTitle}`"
      :value="pageTitle"
    />
    <input
      data-cy="wtph-field-type"
      type="hidden"
      :name="`field_${formFields?.pageType}`"
      :value="pageType"
    />
    <Honeypot ref="honeypot" />
    <p class="u-margin-top--1">
      <small
        >Please <Link href="/contact-us">contact us</Link> if you want to chat,
        as we’re unable to respond to this feedback.</small
      >
    </p>
    <Button
      data-cy="wtph-submit"
      type="submit"
      class="button--primary button--small"
      ><Spinner :show="isLoading" aria-label="Submitting" />{{
        submitBtnText
      }}</Button
    >
  </form>
</template>

<script lang="ts">
import Vue from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import TextArea from "atlas/src/components/TextArea/TextArea.vue";
import Spinner from "atlas/src/components/Spinner/Spinner.vue";
import Link from "../Link/Link.vue";
import Honeypot from "../Honeypot/Honeypot.vue";
import { WtphFormFields } from "./WtphFormFields";
import { wtphForm as wtphFields } from "~/config/formstackForms";
import { stringifyFormData } from "~~/lib/utils/stringifyFormData";
import { Environment } from "~/.aws/lib/types";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Feature.Webform],
});

type Data = {
  feedback: string;
  isLoading: boolean;
  formFields: WtphFormFields | undefined;
};

export default Vue.extend({
  name: "WTPHForm",
  components: {
    Honeypot,
    Button,
    TextArea,
    Link,
    Spinner,
  },
  props: {
    wtph: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      feedback: "",
      isLoading: false,
      formFields: undefined,
    };
  },
  computed: {
    formLabel(): string | undefined {
      if (this.wtph === "Yes") {
        return "Tell us what you found helpful. What were you looking for? (optional)";
      } else if (this.wtph === "No") {
        return "Tell us why this page wasn’t helpful. What were you looking for? (optional)";
      }
      return undefined;
    },
    submitBtnText(): string {
      return this.isLoading ? "Sending feedback" : "Send my feedback";
    },
    pageUrl(): string {
      return window.location.href;
    },
    pageTitle(): string {
      return document.title;
    },
    pageType(): string {
      return this.$store.getters["page/getPageType"];
    },
  },
  mounted() {
    this.getFormstackIds();
  },
  methods: {
    getFormstackIds(): void {
      this.formFields = wtphFields.uat;
      if (
        this.$config?.public?.environment === Environment.PRD ||
        this.$config?.public?.environment === Environment.STAGING
      ) {
        this.formFields = wtphFields.prd;
      }
    },
    async handleSubmit() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.honeypot as any).validate();
        const formData = new FormData(this.$refs.wtphForm as HTMLFormElement);
        await this.submitFormData(stringifyFormData(formData));
      } catch (error) {
        this.isLoading = false;
        logger.error("Error validating WTPH form", error);
      }
    },
    async submitFormData(formData: string): Promise<void> {
      try {
        const response = await $fetch("/api/nuxt/webform", {
          method: "POST",
          body: { formId: this.formFields?.id, formData },
        });
        if (response) {
          this.isLoading = false;
          this.$emit("formSubmitted");
        }
      } catch (error) {
        this.isLoading = false;
        logger.error("Error submitting WTPH form data", error);
      }
    },
  },
});
</script>

<style lang="scss">
@import "./wtph";
</style>
