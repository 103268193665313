<template>
  <DatePopper
    v-model="departingDate"
    :close-on-selected="true"
    :show-date-popper="showDatePopper"
    :locale="$i18n.locale"
    @close="close"
  >
    <Button
      :onClick="toggle"
      class="find-trip-date-filter__trigger-button"
      data-cy="datepopper-trigger"
    >
      <Icon name="calendar" class="find-trip-date-filter__calendar-icon" />
      <span
        class="find-trip-date-filter__trigger-button-text"
        :class="defaultTextClass"
        >{{ buttonText }}</span
      >
      <Icon name="menu-down" class="find-trip-date-filter__dropdown-icon" />
    </Button>
  </DatePopper>
</template>

<script lang="ts">
import Vue from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import DatePopper from "atlas/src/components/DatePopper/DatePopper.vue";
import "atlas/src/components/Icon/icons/calendar.js";
import "atlas/src/components/Icon/icons/menu-down.js";
import dayjs, { extend } from "dayjs";
import localeData from "dayjs/plugin/localeData.js";
import localizedFormat from "dayjs/plugin/localizedFormat.js";
extend(localeData);
extend(localizedFormat);

type Data = {
  departingDate: dayjs.ConfigType;
  showDatePopper: boolean;
};

export default Vue.extend({
  name: "FindTripDateFilter",
  components: {
    DatePopper,
    Button,
    Icon,
  },
  data(): Data {
    return {
      departingDate: "",
      showDatePopper: false,
    };
  },
  computed: {
    buttonText(): string {
      if (this.departingDate) {
        const date = dayjs(this.departingDate)
          .locale(this.$i18n.locale)
          .format("DD MMM YYYY");

        this.$emit("input", date);

        return date;
      }

      this.$emit("input", "");

      return "Anytime";
    },
    defaultTextClass(): {
      "find-trip-date-filter__trigger-button-text--default": boolean;
    } {
      return {
        "find-trip-date-filter__trigger-button-text--default":
          this.buttonText === "Anytime",
      };
    },
  },
  methods: {
    toggle(): void {
      this.showDatePopper = !this.showDatePopper;
    },
    close(): void {
      this.showDatePopper = false;
    },
  },
});
</script>

<style lang="scss">
@import "./find-trip-date-filter";
</style>
