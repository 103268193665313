<template>
  <div
    v-if="showRegionDetector"
    class="l-fixed-action-panel"
    data-cy="region-detector"
  >
    <div class="l-fixed-action-panel__panel region-detector__panel">
      <div class="l-container">
        <div v-if="originCountryName" class="headline--4">
          It looks like you're in
          <span class="u-text-transform--capitalize">{{
            originCountryName
          }}</span>
        </div>
        <p>
          Visit your local site for relevant prices, offers and information.
        </p>
        <Button
          ref="primaryButton"
          class="button--primary u-margin-right--1"
          data-cy="region-detector-go-to"
          @click.native="goto"
        >
          Go to {{ originRegion.name }} site
        </Button>
        <Button
          class="button--unboxed"
          data-cy="region-detector-dismiss"
          @click.native="dismiss"
        >
          Dismiss
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import { PropType } from "@vue/runtime-dom";
import { LocaleObject } from "@nuxtjs/i18n";

export default Vue.extend({
  name: "RegionDetector",
  components: {
    Button,
  },
  props: {
    currentLocale: {
      type: Object as PropType<LocaleObject>,
      required: true,
    },
    preferredRegion: {
      type: String,
      required: false,
      default: "",
    },
    originRegion: {
      type: Object as PropType<LocaleObject>,
      required: true,
    },
    originCountry: {
      type: String,
      required: false,
      default: "",
    },
    originCountryName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      showRegionDetector: false,
    };
  },
  updated() {
    if (this.showRegionDetector) {
      // A11Y - focuses the go to button on display
      ((this.$refs.primaryButton as Vue).$el as HTMLElement).focus();
    }
  },
  mounted() {
    if (
      this.preferredRegion === this.currentLocale.code ||
      this.preferredRegion === this.currentLocale?.enduranceRegion ||
      this.originRegion.code === this.currentLocale.code
    ) {
      this.showRegionDetector = false;
      return;
    }

    this.showRegionDetector = true;
  },
  methods: {
    goto() {
      this.showRegionDetector = false;
      this.$emit("goto", this.originRegion);
    },
    dismiss() {
      this.showRegionDetector = false;
      this.$emit("dismiss", this.preferredRegion);
    },
  },
});
</script>

<style lang="scss">
@import "./region-detector";
</style>
