<template>
  <HeaderBarUtility
    class="u-hidden-xs"
    :title="`My Wishlist${wishlistCount}`"
    :icon="wishlistCount ? 'heart' : 'heart-outline'"
    :href="$link.create('/wishlist')"
    data-cy="header-bar-utility__wishlist"
  />
</template>

<script lang="ts">
import Vue from "vue";
import HeaderBarUtility from "atlas/src/components/HeaderBar/HeaderBarUtility.vue";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Feature.Wishlist],
});

export default Vue.extend({
  name: "WishlistHeaderBarCount",
  components: { HeaderBarUtility },
  computed: {
    wishlistCount() {
      const count = this.$store.getters["wishlist/getWishlistCount"];
      return count > 0 ? ` (${count})` : "";
    },
  },
  mounted() {
    if (process.client) {
      window.addEventListener("pageshow", () => {
        this.updateWishlistStore().catch((err) =>
          logger.error("An exception in updating wishlishHeaderBar pageshow", {
            error: err,
          })
        );
      });
    }
  },
  methods: {
    async updateWishlistStore() {
      await this.$store.dispatch("wishlist/initialize");
    },
  },
});
</script>
