<template>
  <div
    :class="isCompact() ? 'find-trip find-trip--compact' : 'find-trip'"
    :data-cy="isCompact() ? 'find-trip--compact' : 'find-trip'"
  >
    <div class="find-trip__autocomplete">
      <SearchAutocomplete
        :value="value"
        :items="autocompleteResults"
        :placeholder="autocompletePlaceholder"
        :variant="isCompact() ? 'compact' : 'default'"
        @input="updateAutocomplete"
        @clear="clearInput"
        @itemselect="select"
        @select="search"
      />
    </div>

    <div v-if="!isCompact()" class="find-trip__date-filter">
      <FindTripDateFilter v-model="departingDate" />
    </div>

    <Button
      ref="autocompleteButton"
      class="find-trip__button button--special"
      :on-click="searchButtonClicked"
      data-cy="autocomplete-button"
      aria-label="search"
    >
      <span v-if="!isCompact()" class="find-trip__button-text">Search</span>
      <Icon name="arrow-right" class="find-trip__icon"></Icon>
    </Button>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import ProductAutocomplete from "search-client-js/src/Service/ProductAutocomplete";
import AutocompleteModel from "search-client-js/src/Model/Autocomplete";
import SearchModel from "search-client-js/src/Model/Search";
import { Autocomplete } from "search-client-js/autocomplete";
import { mapGetters } from "vuex";
import FindTripDateFilter from "../FindTripDateFilter/FindTripDateFilter.vue";
import SearchAutocomplete from "~/components/Autocomplete/Autocomplete.vue";
import "atlas/src/components/Icon/icons/arrow-right.js";
import "atlas/src/components/Icon/icons/near-me.js";

type Value = {
  id: string;
  value: string;
  icon?: string;
  clearAutocomplete?: boolean;
};

type Data = {
  value: Value;
  autocompleteResults: Value[];
  departingDate: string;
  queryParams: URLSearchParams;
  autocomplete: ProductAutocomplete | undefined;
};

const emptyValue: Value = {
  id: "",
  value: "",
};

const nearMeValue: Value = {
  id: "Near Me",
  value: "Near Me",
  icon: "near-me",
  clearAutocomplete: true,
};

export default Vue.extend({
  name: "FindTrip",
  components: {
    SearchAutocomplete,
    FindTripDateFilter,
    Button,
    Icon,
  },
  props: {
    variant: {
      type: String,
      required: false,
      default: "default",
      validator(val) {
        return val === "default" || val === "compact";
      },
    },
    autocompletePlaceholder: {
      type: String,
      required: false,
      default: "Anywhere",
    },
  },
  data(): Data {
    return {
      value: emptyValue,
      autocompleteResults: [nearMeValue],
      departingDate: "",
      queryParams: new URLSearchParams("?page=1&sort=relevance"),
      autocomplete: undefined,
    };
  },
  created() {
    this.autocomplete = Autocomplete.initProductAutocomplete(
      this.algoliaPublicSearchKey,
      this.$config?.public?.algoliaEnvironment || "dev",
      "intrepid",
      "default"
    );
  },
  methods: {
    searchButtonClicked(): void {
      this.search(this.value);
    },
    updateAutocomplete(value: Value): void {
      this.value = value;

      if (value.id.length < 3) {
        this.autocompleteResults = [nearMeValue];
      } else if (this.autocomplete) {
        this.autocomplete
          .search(value.id)
          .then((search: SearchModel) => {
            // @ts-ignore Fix type in search-client-js
            const searchResults: AutocompleteModel[] = search.results;

            this.autocompleteResults = searchResults.map((result) => ({
              id: result.objectID,
              value: result.highlightedValue,
            }));
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    select(value: Value) {
      if (value.id === nearMeValue.id) {
        this.nearMeSearch(value);
      } else {
        this.keywordSearch(value);
      }
    },
    search(value: Value) {
      this.select(value);
      this.goTo();
    },
    clearInput() {
      this.updateAutocomplete(emptyValue);
    },
    nearMeSearch(value: Value) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.value = value;
            this.queryParams.delete("keyword");
            this.queryParams.set(
              "geoLocation",
              `${position.coords.latitude},${position.coords.longitude}`
            );
          },
          () => {
            this.value = emptyValue;
            window.alert(
              "Intrepid Travel was denied permission to access your location. Give your browser and Intrepid Travel permission to use your current location and try again."
            );
          },
          {
            maximumAge: 3600000,
          }
        );
      } else {
        this.value = emptyValue;
      }
    },
    keywordSearch(value: Value) {
      this.value = value;
      this.queryParams.delete("geoLocation");
      this.queryParams.set("keyword", this.value.id);
    },
    goTo() {
      if (this.departingDate) {
        this.queryParams.set("date_range_from", this.departingDate);
      }

      const href = `/${
        this.$i18n.locale
      }/search?${this.queryParams.toString()}`;

      window.location.href = href;
    },
    isCompact() {
      return this.variant === "compact";
    },
  },
  computed: {
    ...mapGetters("search", ["algoliaPublicSearchKey"]),
  },
});
</script>

<style lang="scss">
@import "./find-trip";
</style>
