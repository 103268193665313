<template>
  <component :is="element" v-if="!!$slots.default">
    <slot v-if="!error"></slot>
  </component>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "ErrorBoundary",
  props: {
    element: {
      type: String,
      default: "div",
    },
  },
  data: () => ({
    error: false,
  }),
  errorCaptured(err) {
    this.error = true;
    this.$handleError(err);
    return false;
  },
});
</script>
