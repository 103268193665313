<template>
  <div class="footer u-margin-top--3" data-cy="footer">
    <!-- There is a bug in the way Vue hydrates this component where the siblings of the <hr /> get hydrated as children instead. Wrapping the <hr> in the <div> seems to resolve this -->
    <div><hr /></div>
    <div class="l-container" data-cy="footer-top">
      <div class="l-grid u-margin-top--2">
        <div
          class="
            l-grid__cell l-grid__cell--4-col l-grid__cell--8-col-tablet
            xs:u-margin-bottom--3
            md:u-margin-bottom--0
            lg:margin-bottom--3
          "
        >
          <div class="l-grid lg:u-margin-right--2">
            <div
              class="
                l-grid__cell l-grid__cell--4-col l-grid__cell--12-col-desktop
              "
            >
              <RegionSelector
                :currentRegion="currentLocale"
                :availableRegions="availableRegions"
              />
            </div>
            <div
              class="
                l-grid__cell l-grid__cell--4-col l-grid__cell--12-col-desktop
              "
            >
              <Subscription />
            </div>
          </div>
        </div>
        <div
          v-for="menu in footerMenu"
          :key="menu.name"
          class="l-grid__cell l-grid__cell--2-col"
        >
          <FooterMenu :menu="menu" />
        </div>
        <div v-if="acknowledgement" class="l-grid__cell l-grid__cell--12-col">
          <hr />
          <div
            class="
              lg:u-margin-left--4 lg:u-margin-right--4
              u-text-align--center
              u-padding-top--1
            "
            data-cy="acknowledgement"
          >
            {{ acknowledgement }}
          </div>
        </div>
      </div>
    </div>

    <div class="u-background-color--midnight u-margin-top--4">
      <div
        class="l-container u-color--white u-padding-top--2 u-padding-bottom--2"
      >
        <div class="l-grid">
          <div
            class="
              l-grid__cell
              l-grid__cell--1-col-phone
              l-grid__cell--2-col-tablet
              l-grid__cell--5-col-desktop
              l-grid__cell--flex
              u-align-items--center
              u-margin-bottom--2
            "
          >
            <Link href="/purpose/b-corp" target="_blank">
              <Imagery
                lazy-src="/files/images/logos/b-corp-logo.svg"
                alt="B Corp Logo"
                :width="50"
                :height="86"
                type="static"
              ></Imagery>
            </Link>
          </div>
          <div
            class="
              l-grid__cell
              l-grid__cell--3-col-phone
              l-grid__cell--6-col-tablet
              l-grid__cell--7-col-desktop
              l-grid__cell--flex
              u-align-items--center
              u-justify-content--flex-end
              u-margin-bottom--2
            "
            data-cy="footer-social-media-links"
          >
            <Link
              :href="
                $config && $config.public && $config.public.facebookUrl
                  ? $config.public.facebookUrl
                  : ''
              "
              target="_blank"
              rel="noopener"
              class="u-color--white u-margin-right--1"
            >
              <Icon
                label="Facebook"
                class="icon--size-2"
                name="facebook"
              ></Icon>
            </Link>
            <Link
              :href="
                $config && $config.public && $config.public.instagramUrl
                  ? $config.public.instagramUrl
                  : ''
              "
              target="_blank"
              rel="noopener"
              class="u-color--white u-margin-right--1"
            >
              <Icon
                label="Instagram"
                class="icon--size-2"
                name="instagram"
              ></Icon>
            </Link>
            <Link
              :href="
                $config && $config.public && $config.public.tiktokUrl
                  ? $config.public.tiktokUrl
                  : ''
              "
              target="_blank"
              rel="noopener"
              class="u-color--white u-margin-right--1"
            >
              <Icon label="Tiktok" class="icon--size-2" name="tiktok"></Icon>
            </Link>
            <Link
              :href="
                $config && $config.public && $config.public.xUrl
                  ? $config.public.xUrl
                  : ''
              "
              target="_blank"
              rel="noopener"
              class="u-color--white u-margin-right--1"
            >
              <Icon label="X" class="icon--size-2" name="x"></Icon>
            </Link>
            <Link
              :href="
                $config && $config.public && $config.public.youtubeUrl
                  ? $config.public.youtubeUrl
                  : ''
              "
              target="_blank"
              rel="noopener"
              class="u-color--white u-margin-right--1"
            >
              <Icon label="Youtube" class="icon--size-2" name="youtube"></Icon>
            </Link>
            <Link
              :href="
                $config && $config.public && $config.public.linkedinUrl
                  ? $config.public.linkedinUrl
                  : ''
              "
              target="_blank"
              rel="noopener"
              class="u-color--white u-margin-right--1"
            >
              <Icon
                label="Linkedin"
                class="icon--size-2"
                name="linkedin"
              ></Icon>
            </Link>
            <Link
              href="https://www.intrepidtravel.com/adventures/"
              target="_blank"
              rel="noopener"
              class="u-color--white u-margin-right--1 lg:u-margin-right--0"
            >
              <Icon label="Blog" class="icon--size-2" name="hangouts"></Icon>
            </Link>
          </div>
          <FooterAccreditations />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import RegionSelector from "../RegionSelector/RegionSelector.vue";
import Subscription from "../Subscription/Subscription.vue";
import Link from "../Link/Link.vue";
import FooterMenu from "./FooterMenu.vue";
import FooterAccreditations from "./FooterAccreditations.vue";
import { footerMenu } from "./footerMenu.json";
import locales from "~~/config/locales";
import "atlas/src/components/Icon/icons/facebook.js";
import "atlas/src/components/Icon/icons/hangouts.js";
import "atlas/src/components/Icon/icons/instagram.js";
import "atlas/src/components/Icon/icons/linkedin.js";
import "atlas/src/components/Icon/icons/x.js";
import "atlas/src/components/Icon/icons/youtube.js";

export default Vue.extend({
  name: "Footer",
  components: {
    FooterMenu,
    FooterAccreditations,
    Imagery,
    Icon,
    RegionSelector,
    Link,
    Subscription,
  },
  data() {
    return {
      currentLocale: this.$i18n.locale,
      availableRegions: locales,
      footerMenu,
    };
  },
  computed: {
    acknowledgement(): string | undefined {
      if (this.$i18n.locale !== "au") return;
      return "As an Australian-owned business, we acknowledge the Traditional Owners of Country throughout Australia and their continuing connection to land, waters and community. We pay our respects to them and their cultures, and to their Elders past and present.";
    },
  },
});
</script>
