<template>
  <div class="l-container md:u-margin-top--6 xs:u-margin-top--4">
    <div
      class="
        u-background-color--sand
        u-padding-bottom--2
        u-padding-top--2
        u-padding-right--2
        u-padding-left--2
      "
    >
      <template v-if="!isFeedbackSubmitted">
        <p class="headline--5 u-margin-bottom--0.5 u-margin-top--0">
          Was this page helpful?
        </p>
        <Button
          class="button--secondary button--small u-margin-right--1"
          :class="[isYesClicked ? 'wtph__button--selected' : '']"
          data-cy="wtph-yes-btn"
          :on-click="handleYesClick"
          >Yes</Button
        >
        <Button
          class="button--secondary button--small"
          :class="[isNoClicked ? 'wtph__button--selected' : '']"
          data-cy="wtph-no-btn"
          :on-click="handleNoClick"
          >No</Button
        >
        <WTPHForm
          v-if="showForm"
          class="u-margin-top--2"
          :wtph="wtph"
          @formSubmitted="showSuccessMessage"
        />
      </template>
      <template v-else>
        <p class="headline--4 u-margin-top--0">Thanks for your feedback</p>
        <p>
          Your feedback means the world to us, and we appreciate the time taken
          to help us improve this page.
        </p>
        <p class="u-margin-top--1 u-margin-bottom--0">
          <small
            >Please <Link href="/contact-us">contact us</Link> if you want to
            chat, as we’re unable to respond to this feedback.</small
          >
        </p>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Link from "../Link/Link.vue";
import WTPHForm from "./WTPHForm.vue";

type Data = {
  isYesClicked: boolean;
  isNoClicked: boolean;
  isFeedbackSubmitted: boolean;
};

export default Vue.extend({
  name: "WTPH",
  components: {
    WTPHForm,
    Button,
    Link,
  },
  data(): Data {
    return {
      isYesClicked: false,
      isNoClicked: false,
      isFeedbackSubmitted: false,
    };
  },
  computed: {
    showForm(): boolean {
      return this.isYesClicked || this.isNoClicked;
    },
    wtph(): string {
      return this.isYesClicked ? "Yes" : "No";
    },
  },
  methods: {
    resetButtonClicks(): void {
      this.isYesClicked = false;
      this.isNoClicked = false;
    },
    handleYesClick(): void {
      this.resetButtonClicks();
      this.isYesClicked = true;
    },
    handleNoClick(): void {
      this.resetButtonClicks();
      this.isNoClicked = true;
    },
    showSuccessMessage(): void {
      this.isFeedbackSubmitted = true;
    },
  },
});
</script>

<style lang="scss">
@import "./wtph";
</style>
