<template>
  <Modal
    :show-modal="showModal"
    description="Subscribe to Intrepid"
    @close="closeModal"
  >
    <template slot="heading">
      <div class="u-text-align--center u-margin-bottom--2">
        <Imagery
          lazy-src="/files/images/logos/intrepid-logo.svg"
          alt="Intrepid Logo"
          type="static"
          data-cy="subscription__modal-logo"
        />
      </div>
    </template>
    <template slot="content">
      <p class="u-font-weight--bold" data-cy="subscribe_modal-heading">
        <template v-if="submitted">You're subscribed</template>
        <template v-else>Subscribe for travel deals and inspiration</template>
      </p>
      <template v-if="submitted">
        <p data-cy="subscription__modal-description">
          Now that you're subscribed, we'll brighten your inbox with hot deals,
          new trips, travel inspiration and more. Don't wait for us though,
          start exploring trips to find some inspiration of your own.
        </p>
        <Button
          :href="$link.create('/search')"
          :router-link="false"
          class="button--secondary button--block u-margin-top--1"
          data-cy="subscription__explore-all-trips"
        >
          Explore all trips
        </Button>
      </template>
      <template v-else>
        <Alert
          v-if="error"
          type="danger"
          data-cy="subscription-form__error"
          class="u-margin-bottom--1"
        >
          <div slot="content">Error submitting the form and try again.</div>
        </Alert>
        <form
          v-if="formstackIds"
          action="#"
          data-cy="subscription-form"
          @submit.prevent
        >
          <TextField
            id="subscription-form__first-name"
            v-model="subscriptionForm.firstName"
            type="text"
            name="first-name"
            label="First name*"
            :error="errorsData.firstName"
            data-cy="subscription-form__first-name"
            @input="validate('firstName')"
          />
          <TextField
            id="subscription-form__last-name"
            v-model="subscriptionForm.lastName"
            type="text"
            name="last-name"
            label="Last name*"
            :error="errorsData.lastName"
            data-cy="subscription-form__last-name"
            @input="validate('lastName')"
          />
          <SelectField
            id="subscription-form__country"
            v-model="subscriptionForm.country"
            type="text"
            name="country"
            :options="computedCountries"
            :error="errorsData.country"
            label="Country of residence*"
            data-cy="subscription-form__country"
            @input="validate('country')"
          />
          <TextField
            id="subscription-form__email"
            v-model="subscriptionForm.email"
            type="text"
            name="email"
            label="Email*"
            :error="errorsData.email"
            data-cy="subscription-form__email"
            @input="validate('email')"
          />
          <Checkbox
            id="subscription-form__privacy-policy"
            v-model="subscriptionForm.privacyPolicy"
            :required="true"
            :error="errorsData.privacyPolicy"
            data-cy="subscription-form__privacy-policy"
            @change="validate('privacyPolicy')"
          >
            I have read and agree to the privacy policy*<br />
            <small>
              Please read our
              <a
                :href="$link.create('/booking-intrepid/privacy')"
                target="_blank"
              >
                privacy policy
              </a>
            </small>
          </Checkbox>
          <Honeypot ref="honeypot" />
          <Button
            class="button button--primary button--block u-margin-top--2"
            data-cy="subscription-form__button"
            :onClick="submitForm"
          >
            <Spinner
              :show="sending"
              aria-label="Submitting"
              class="u-margin-right--1"
            />
            Subscribe
          </Button>
        </form>
      </template>
    </template>
  </Modal>
</template>

<script lang="ts">
import * as Yup from "yup";
import Vue, { PropType } from "vue";
import TextField from "atlas/src/components/TextField/TextField.vue";
import SelectField from "atlas/src/components/SelectField/SelectField.vue";
import Checkbox from "atlas/src/components/Checkbox/Checkbox.vue";
import Spinner from "atlas/src/components/Spinner/Spinner.vue";
import Alert from "atlas/src/components/Alert/Alert.vue";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import Button from "atlas/src/components/Button/Button.vue";
import Honeypot from "../Honeypot/Honeypot.vue";
import { subscriptionFormValidator } from "./SubscriptionFormValidator";
import { countries } from "~/config/countries";
import { Country } from "~/lib/types/Country";
import { SubscriptionFormFields } from "~/components/Subscription/Props";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
import { SubscriptionFormMapper } from "~/components/Subscription/SubscriptionFormMapper";

const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Feature.Webform],
});

interface ValidationErrors {
  [fieldName: string]: string[];
}

export default Vue.extend({
  name: "SubscriptionForm",
  components: {
    TextField,
    SelectField,
    Checkbox,
    Honeypot,
    Spinner,
    Modal,
    Imagery,
    Alert,
    Button,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    formstackIds: {
      type: Object as PropType<SubscriptionFormFields>,
      required: true,
    },
  },
  data() {
    return {
      submitted: false,
      sending: false,
      error: false,
      errorsData: {
        firstName: [],
        lastName: [],
        country: [],
        email: [],
        privacyPolicy: [],
      } as ValidationErrors,
      subscriptionForm: {
        firstName: "",
        lastName: "",
        country: "",
        email: "",
        privacyPolicy: false,
      },
      schema: subscriptionFormValidator,
    };
  },
  computed: {
    computedCountries() {
      const countryList = [];
      countryList.push({
        id: "",
        value: "",
      });

      countries.forEach((country: Country) => {
        countryList.push({
          id: country.iso2Code.toUpperCase(),
          value: country.name,
        });
      });

      return countryList;
    },
  },
  methods: {
    closeModal(): void {
      this.$emit("close");
    },
    async submitForm(): Promise<void> {
      if (this.sending) return;
      try {
        const honeypot = this.$refs.honeypot as
          | InstanceType<typeof Honeypot>
          | undefined;

        if (honeypot) {
          honeypot.validate();
        }

        await this.schema.validate(this.subscriptionForm, {
          abortEarly: false,
        });

        await this.sendFormData(this.mapFormData());
      } catch (errors: unknown) {
        if (errors instanceof Yup.ValidationError) {
          errors.inner.forEach((error) => {
            if (error.path) {
              this.errorsData[error.path] = [error.message];
            }
          });
        }
      }
    },
    mapFormData() {
      const formData = {
        firstName: this.subscriptionForm.firstName,
        lastName: this.subscriptionForm.lastName,
        email: this.subscriptionForm.email,
        countryOfResidence: this.subscriptionForm.country,
        sourceType: "IG Website",
        source: "Newsletter",
      };
      return SubscriptionFormMapper(this.formstackIds, formData);
    },
    async sendFormData(formData: { [key: string]: string | boolean }) {
      try {
        this.sending = true;
        const response = await $fetch("/api/nuxt/webform", {
          method: "POST",
          body: {
            formId: this.formstackIds.id,
            formData: JSON.stringify(formData),
          },
        });
        if (response === undefined) {
          this.error = true;
        } else {
          this.submitted = true;
        }
        this.sending = false;
      } catch (error) {
        logger.error("Error submit form data to FORMSTACK ", error);
        this.error = true;
        this.sending = false;
      }
    },
    validate(field: string) {
      try {
        this.schema.validateSyncAt(field, this.subscriptionForm);
        this.errorsData[field] = [];
      } catch (error: unknown) {
        if (error instanceof Yup.ValidationError) {
          this.errorsData[field] = [error.message];
        }
      }
    },
  },
});
</script>
