import { render, staticRenderFns } from "./WTPHForm.vue?vue&type=template&id=317b3035&"
import script from "./WTPHForm.vue?vue&type=script&lang=ts&"
export * from "./WTPHForm.vue?vue&type=script&lang=ts&"
import style0 from "./WTPHForm.vue?vue&type=style&index=0&id=317b3035&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports