<template>
  <!-- eslint-disable-next-line vue/require-component-is -->
  <component v-bind="computedProps">
    <slot />
  </component>
</template>

<script lang="ts">
import Vue from "vue";
import { isFullyQualifiedLink } from "../../lib/utils/link/isFullyQualifiedLink";
import { isBlogLink } from "../../lib/utils/link/isBlogLink";

export default Vue.extend({
  name: "Link",
  props: {
    href: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    computedProps(): Object {
      if (this.href === "<nolink>") {
        return {
          is: "span",
        };
      }

      if (
        this.isInternalUrl(this.href) &&
        !this.$agents.getIsAgentLoggedIn() &&
        this.$link.getIsAllowedAsNuxtLinks(this.href)
      ) {
        return {
          is: "NuxtLink",
          to: this.getHref(this.href),
        };
      }

      return {
        is: "a",
        href: this.getHref(this.href),
        target: this.target,
      };
    },
  },
  methods: {
    getHref(href: string): string {
      if (!this.isInternalUrl(href)) {
        return href;
      }

      return this.$link.create(href);
    },
    isInternalUrl(href: string): boolean {
      return !(isFullyQualifiedLink(href) || isBlogLink(href));
    },
  },
});
</script>
