var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],ref:"megaMenu",staticClass:"mega-menu",style:(_vm.menuPosition),attrs:{"data-cy":"mega-menu","aria-label":"Navigation Submenu"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;return _vm.close.apply(null, arguments)}}},_vm._l((_vm.menu),function(level1,level1Index){return _c('div',{key:level1Index,staticClass:"mega-menu__level-1",class:{
      'mega-menu__level-1--visible': level1Index === _vm.activeLevels[1],
    },attrs:{"data-cy":"mega-menu__level-1"}},[(_vm.hasChildren(level1.children))?_c('div',{staticClass:"mega-menu__level-2"},[_c('ul',{ref:"level2Items",refInFor:true,staticClass:"mega-menu__level-2-items"},_vm._l((level1.children),function(level2,level2Index){return _c('li',{key:_vm.uniqueRefKey(level1Index, level2Index),ref:_vm.uniqueRefKey(level1Index, 'level2Item'),refInFor:true,staticClass:"mega-menu__level-2-item"},[(!_vm.hasChildren(level2.children))?_c('Link',{ref:_vm.uniqueRefKey(level1Index, level2Index, 'link'),refInFor:true,staticClass:"mega-menu__level-2-link",attrs:{"href":level2.href,"data-cy":"mega-menu__level-2-link"}},[_vm._v("\n            "+_vm._s(level2.name)+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.hasChildren(level2.children))?_c('button',{ref:_vm.uniqueRefKey(level1Index, level2Index, 'link'),refInFor:true,staticClass:"mega-menu__level-2-link",class:{
              'mega-menu__level-2-link--active':
                level2Index === _vm.activeLevels[2],
            },attrs:{"data-cy":"mega-menu__level-2-link"},on:{"click":function($event){return _vm.level2Clicked(level2Index)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.level2Clicked(level2Index)}}},[_vm._v("\n            "+_vm._s(level2.name)+"\n            "),_c('Icon',{staticClass:"mega-menu__level-2-link-chevron",attrs:{"name":"chevron-right"}})],1):_vm._e(),_vm._v(" "),(_vm.hasChildren(level2.children))?_c('div',{staticClass:"mega-menu__level-3",class:{
              'mega-menu__level-3--visible':
                level1Index === _vm.activeLevels[1] &&
                level2Index === _vm.activeLevels[2],
            },attrs:{"href":level2.href}},[_c('div',{staticClass:"mega-menu__remove-inner-shadow"}),_vm._v(" "),_c('Link',{staticClass:"mega-menu__level-3-title",attrs:{"href":level2.href,"data-cy":"mega-menu__level-3-link"}},[_vm._v("\n              "+_vm._s(level2.name)+"\n            ")]),_vm._v(" "),_c('ul',{staticClass:"mega-menu__level-3-items",class:_vm.level3ItemsClasses(level2.children)},_vm._l((level2.children),function(level3,level3Index){return _c('li',{key:_vm.uniqueRefKey(level1Index, level2Index, level3Index),staticClass:"mega-menu__level-3-item",on:{"click":_vm.close}},[_c('Link',{staticClass:"mega-menu__level-3-link",attrs:{"href":level3.href,"data-cy":"mega-menu__level-3-link"}},[_vm._v("\n                  "+_vm._s(level3.name)+"\n                ")])],1)}),0)],1):_vm._e()],1)}),0)]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }