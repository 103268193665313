import * as Yup from "yup";
import { isValidEmail } from "~/lib/utils/isValidEmail";

export const subscriptionFormValidator = Yup.object().shape({
  firstName: Yup.string()
    .trim("Please enter your first name")
    .max(128, "First name cannot be longer than 128 characters")
    .required("Please enter your first name"),
  lastName: Yup.string()
    .trim("Please enter your first name")
    .max(128, "Last name cannot be longer than 128 characters")
    .required("Please enter your last name"),
  country: Yup.string().required("Please select your country of residence"),
  email: Yup.string()
    .trim("Please enter your email")
    .email("Please enter a valid email address")
    .required("Please enter your email")
    .test("Validate Email", "Please enter a valid email address", (value) => {
      if (!value) return true;
      return isValidEmail(value);
    }),
  privacyPolicy: Yup.boolean()
    .required("Please check the box")
    .oneOf([true], "Please check the box"),
});
