<template>
  <div
    style="
      position: absolute;
      z-index: -1;
      opacity: 0;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
    "
  >
    <label for="your-name">Name</label>
    <input
      id="your-name"
      ref="honeypot"
      v-model="value"
      name="your-name"
      autocomplete="your-name"
      tabindex="-1"
      type="text"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "Honeypot",
  data() {
    return {
      value: "",
    };
  },
  methods: {
    validate() {
      if (this.value || (this.$refs.honeypot as HTMLInputElement).value) {
        throw new Error(
          "Looks like you are a bot. You have filled out the legendary honeypot field!"
        );
      }
    },
  },
});
</script>
